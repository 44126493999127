<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loading">
                <template>
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(generalSubmit)" @reset.prevent="reset">
                            <b-row style="
                display: flex;
                flex-wrap: wrap;">
                                <b-col lg="12" sm="12">
                                    <iq-card>
                                        <template v-slot:headerTitle>
                                            <h5 class="card-title"><!--<i class="ri-edit-line"></i> -->{{
                                                $t('research_results.sample_test_result_submission') }}</h5>
                                        </template>
                                        <template v-slot:body>
                                                    <iq-card class="p-3">
                                                        <b-row>
                                                            <b-col md="12" class="pb-3">
                                                                <div
                                                                    style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);">
                                                                    <h5 class="text-white text-center">
                                                                        {{$t('research_results.seed_information')}}</h5>
                                                                </div>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <b-form-group class="row" label-cols-sm="6"
                                                                              label-for="seed_infos_id">
                                                                    <template v-slot:label>
                                                                        {{$t('research_results.lot_no')}}
                                                                    </template>
                                                                    <b-form-select
                                                                        id="seed_infos_id"
                                                                        v-model="formData.seed_infos_id"
                                                                        :options="lotNumberList"
                                                                        @change="getSeedInfos"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                        >
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="loads">
                                                                <b-form-group class="row" label-cols-sm="6" label-for="seed_name">
                                                                    <template v-slot:label>
                                                                        {{$t('tag_print.seed_name')}}
                                                                    </template>
                                                                    <b-form-input
                                                                        disabled
                                                                        plain
                                                                        id="seed_name"
                                                                        v-model="tmpData.seed_name"
                                                                    >
                                                                    </b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="loads">
                                                                <b-form-group class="row" label-cols-sm="6"
                                                                              label-for="seed_variety">
                                                                    <template v-slot:label>
                                                                        {{$t('tag_print.seed_variety_name')}}
                                                                    </template>
                                                                    <b-form-input
                                                                        disabled
                                                                        plain
                                                                        id="seed_variety"
                                                                        v-model="tmpData.seed_variety"
                                                                        v-if="tmpData.seed_name_id != 6"
                                                                    >
                                                                    </b-form-input>
                                                                    <b-form-input
                                                                        v-else-if="tmpData.seed_name_id == 6"
                                                                        disabled
                                                                        plain
                                                                        id="seed_variety_others_name"
                                                                        v-model="tmpData.seed_variety_others_name"
                                                                    ></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="loads">
                                                                <b-form-group class="row" label-cols-sm="6"
                                                                              label-for="seed_class">
                                                                    <template v-slot:label>
                                                                        {{$t('tag_print.seed_class')}}
                                                                    </template>
                                                                    <b-form-input
                                                                        disabled
                                                                        plain
                                                                        id="seed_class"
                                                                        v-model="tmpData.seed_class"
                                                                    >
                                                                    </b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row v-if="loads">
                                                            <b-col md="12" class="pb-3">
                                                                <div
                                                                    style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);">
                                                                    <h5 class="text-white text-center">
                                                                        {{$t('research_results.seed_test_result')}}</h5>
                                                                </div>
                                                                {{formData.quality}}
                                                            </b-col>
                                                            <b-col md="6" v-if="loads">
                                                                <b-form-group class="row" label-cols-sm="6"
                                                                              label-for="lab_name">
                                                                    <template v-slot:label>
                                                                        {{$t('research_results.lab_name')}}
                                                                    </template>
                                                                    <b-form-select
                                                                        id="seed_infos_id"
                                                                        disabled
                                                                        plain
                                                                        :options="officeList"
                                                                        v-model="tmpData.lab_name"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                        >
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <b-form-group class="row" label-cols-sm="6"
                                                                              label-for="seed_source_name">
                                                                    <template v-slot:label>
                                                                        {{$t('research_results.seed_source_name')}}
                                                                    </template>
                                                                    <b-form-input
                                                                        disabled
                                                                        plain
                                                                        id="seed_source_name"
                                                                        v-model="tmpData.seed_source_name"
                                                                    >
                                                                    </b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <b-form-group class="row" label-cols-sm="6"
                                                                              label-for="organization_address">
                                                                    <template v-slot:label>
                                                                        {{$t('research_results.organization_address')}}
                                                                    </template>
                                                                    <b-form-textarea
                                                                        disabled
                                                                        plain
                                                                        id="organization_address"
                                                                        v-model="formData.organization_address"
                                                                    >
                                                                    </b-form-textarea>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <b-form-group class="row" label-cols-sm="6"
                                                                              label-for="sample_collection_date">
                                                                    <template v-slot:label>
                                                                        {{$t('research_results.sample_collection_date')}}
                                                                    </template>
                                                                    <b-form-input
                                                                        disabled
                                                                        class="datepicker"
                                                                        id="sample_collection_date"
                                                                        v-model="formData.collection_date"
                                                                    >
                                                                    </b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <ValidationProvider
                                                                    :name="$t('research_results.seed_examination_start_date')"
                                                                    vid="seed_exam_start_date" rules="required">
                                                                    <b-form-group class="row" label-cols-sm="6"
                                                                                  label-for="seed_exam_start_date"
                                                                                  slot-scope="{ valid, errors }">
                                                                        <template v-slot:label>
                                                                            {{$t('research_results.seed_examination_start_date')}}
                                                                        <span class="text-danger">*</span>
                                                                        </template>
                                                                        <flat-pickr class="form-control"
                                                                            v-model="formData.seed_exam_start_date"
                                                                            id="seed_exam_start_date"
                                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                                        >
                                                                        </flat-pickr>
                                                                        <div class="invalid-feedback">
                                                                            {{ errors[0] }}
                                                                        </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <ValidationProvider
                                                                    :name="$t('research_results.seed_examination_end_date')"
                                                                    vid="seed_exam_end_date" rules="required">
                                                                    <b-form-group class="row" label-cols-sm="6"
                                                                                  label-for="seed_exam_end_date"
                                                                                  slot-scope="{ valid, errors }">
                                                                        <template v-slot:label>
                                                                            {{$t('research_results.seed_examination_end_date')}}
                                                                            <span class="text-danger">*</span>
                                                                        </template>
                                                                        <flat-pickr class="form-control"
                                                                            v-model="formData.seed_exam_end_date"
                                                                            id="seed_exam_end_date"
                                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                                        >
                                                                        </flat-pickr>
                                                                        <div class="invalid-feedback">
                                                                            {{ errors[0] }}
                                                                        </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col md="12" class="pb-3">
                                                                <div
                                                                    style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);">
                                                                    <h5 class="text-white text-center">
                                                                        {{$t('research_results.test_name_seed_quality')}}</h5>
                                                                </div>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <iq-card class="p-3">
                                                                    <b-row>
                                                                        <b-col md="6" class="pb-3">
                                                                            <div
                                                                                style="font-size: 12px; background-color: rgb(51, 121, 130); padding: 6px;">
                                                                                <h5 class="text-white text-center">
                                                                                    {{$t('research_results.purity_test')}}</h5>
                                                                            </div>
                                                                        </b-col>
                                                                        <b-col md="6"></b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.pure_seed_result')"
                                                                                vid="pure_seed_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="pure_seed_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.pure_seed_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="pure_seed_result"
                                                                                        v-model="formData.pure_seed_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="pure_seed_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.pure_seed_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                    plain
                                                                                    id="pure_seed_quality"
                                                                                    v-model="formData.pure_seed_quality"
                                                                                ></b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="pure_seed_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="pure_seed_result_comments"
                                                                                    v-model="formData.pure_seed_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.inert_substance_result')"
                                                                                vid="inert_substance_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="inert_substance_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.inert_substance_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="insert_substance_result"
                                                                                        v-model="formData.inert_substance_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="inert_substance_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.inert_substance_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                    plain
                                                                                    id="inert_substance_quality"
                                                                                    v-model="formData.inert_substance_quality"
                                                                                ></b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <!-- <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="inert_substance_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="inert_substance_comments"
                                                                                    v-model="formData.inert_substance_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group> -->
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.others_seed_result')"
                                                                                vid="others_seed_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="others_seed_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.others_seed_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="others_seed_result"
                                                                                        v-model="formData.others_seed_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="others_seed_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.others_seed_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    disabled
                                                                                    plain
                                                                                    id="others_seed_quality"
                                                                                    v-model="formData.others_seed_quality"
                                                                                    v-if="tmpData.seed_name_id != 6"
                                                                                >
                                                                                </b-form-input>
                                                                                <b-form-input
                                                                                    v-else
                                                                                    plain
                                                                                    id="others_seed_quality"
                                                                                    v-model="formData.others_seed_quality"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <!-- <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="others_seed_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="others_seed_result_comments"
                                                                                    v-model="formData.others_seed_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group> -->
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.seed_of_other_crops_result')"
                                                                                vid="seed_of_other_crops_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="seed_of_other_crops_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.seed_of_other_crops_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="seed_of_other_crops_result"
                                                                                        v-model="formData.seed_of_other_crops_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="seed_of_other_crops_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.seed_of_other_crops_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="seed_of_other_crops_quality"
                                                                                    v-model="formData.seed_of_other_crops_quality"
                                                                                    :state="!errors[0] && (valid || null)"
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                ></b-form-input>

                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <!-- <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="seed_of_other_crops_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="seed_of_other_crops_result_comments"
                                                                                    v-model="formData.seed_of_other_crops_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group> -->
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.weed_seeds_result')"
                                                                                vid="weed_seed_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="weed_seed_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.weed_seeds_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="weed_seed_result"
                                                                                        v-model="formData.weed_seed_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="weed_seed_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.weed_seeds_quality')}}
                                                                                </template>
                                                                               <b-form-input
                                                                                    plain
                                                                                    id="weed_seed_quality"
                                                                                    v-model="formData.weed_seed_quality"
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                ></b-form-input>

                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <!-- <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="weed_seed_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="weed_seed_result_comments"
                                                                                    v-model="formData.weed_seed_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group> -->
                                                                        </b-col>
                                                                        <b-col md="6" class="pb-3">
                                                                            <div
                                                                                style="font-size: 12px; background-color: rgb(51, 121, 130); padding: 6px;">
                                                                                <h5 class="text-white text-center">
                                                                                    {{$t('research_results.germination_test')}}</h5>
                                                                            </div>
                                                                        </b-col>
                                                                        <b-col md="6"></b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.normal_seed_result')"
                                                                                vid="normal_seed_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="normal_seed_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.normal_seed_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="normal_seed_result"
                                                                                        v-model="formData.normal_seed_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="normal_seed_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.normal_seed_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="normal_seed_quality"
                                                                                    v-model="formData.normal_seed_quality"
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                ></b-form-input>

                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="normal_seed_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="normal_seed_result_comments"
                                                                                    v-model="formData.normal_seed_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.unusual_seed_result')"
                                                                                vid="unusual_seed_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="unusual_seed_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.unusual_seed_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="unusual_seed_result"
                                                                                        v-model="formData.unusual_seed_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="unusual_seed_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.unusual_seed_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="unusual_seed_quality"
                                                                                    v-model="formData.unusual_seed_quality"
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                ></b-form-input>

                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <!-- <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="unusual_seed_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="unusual_seed_result_comments"
                                                                                    v-model="formData.unusual_seed_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group> -->
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.fresh_seed_result')"
                                                                                vid="fresh_seed_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="fresh_seed_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.fresh_seed_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="fresh_seed_result"
                                                                                        v-model="formData.fresh_seed_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="fresh_seed_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.fresh_seed_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="fresh_seed_quality"
                                                                                    v-model="formData.fresh_seed_quality"
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                ></b-form-input>

                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <!-- <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="fresh_seed_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="fresh_seed_result_comments"
                                                                                    v-model="formData.fresh_seed_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group> -->
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.hard_seeds_result')"
                                                                                vid="hard_seed_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="hard_seed_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.hard_seeds_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="hard_seed_result"
                                                                                        v-model="formData.hard_seed_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="hard_seed_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.hard_seeds_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="hard_seed_quality"
                                                                                    v-model="formData.hard_seed_quality"
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                ></b-form-input>

                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <!-- <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="hard_seed_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="hard_seed_result_comments"
                                                                                    v-model="formData.hard_seed_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group> -->
                                                                        </b-col>

                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.dead_seed_result')"
                                                                                vid="dead_seed_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="dead_seed_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.dead_seed_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="dead_seed_result"
                                                                                        v-model="formData.dead_seed_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="dead_seed_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.dead_seed_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="dead_seed_quality"
                                                                                    v-model="formData.dead_seed_quality"
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                ></b-form-input>

                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <!-- <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="dead_seed_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="dead_seed_result_comments"
                                                                                    v-model="formData.dead_seed_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group> -->
                                                                        </b-col>
                                                                        <b-col md="6" class="pb-3">
                                                                            <div
                                                                                style="font-size: 12px; background-color: rgb(51, 121, 130); padding: 6px;">
                                                                                <h5 class="text-white text-center">
                                                                                    {{$t('research_results.humidity_test')}}</h5>
                                                                            </div>
                                                                        </b-col>
                                                                        <b-col md="6"></b-col>
                                                                        <b-col md="4">
                                                                            <ValidationProvider
                                                                                :name="$t('research_results.humidity_test_result')"
                                                                                vid="humidity_test_result"
                                                                                rules="required|max:50">
                                                                                <b-form-group class="row"
                                                                                              label-cols-sm="4"
                                                                                              label-for="humidity_test_result"
                                                                                              slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                        {{$t('research_results.humidity_test_result')}}
                                                                                        <span
                                                                                            class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        plain
                                                                                        id="humidity_test_result"
                                                                                        v-model="formData.humidity_test_result"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                    >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="humidity_test_quality">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.humidity_test_quality')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="humidity_test_quality"
                                                                                    v-model="formData.humidity_test_quality"
                                                                                    :disabled="lotNumberList[0].seed_name_id !== 6"
                                                                                ></b-form-input>

                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4">
                                                                            <b-form-group class="row" label-cols-sm="4"
                                                                                          label-for="humidity_test_result_comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-input
                                                                                    plain
                                                                                    id="humidity_test_result_comments"
                                                                                    v-model="formData.humidity_test_result_comments"
                                                                                >
                                                                                </b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="8">
                                                                            <b-form-group class="row" label-cols-sm="2"
                                                                                          label-for="comments">
                                                                                <template v-slot:label>
                                                                                    {{$t('research_results.comments')}}
                                                                                </template>
                                                                                <b-form-textarea
                                                                                    plain
                                                                                    id="comments"
                                                                                    v-model="formData.comments"
                                                                                >
                                                                                </b-form-textarea>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="4"></b-col>
                                                                    </b-row>
                                                                </iq-card>
                                                            </b-col>
                                                        </b-row>
                                                    </iq-card>
                                                <div class="row" v-if="loads">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" :disabled="isLoading" variant="primary"
                                                              class="mr-2">{{ saveBtnName }}
                                                    </b-button>
                                                    &nbsp;
                                                    <b-link class="btn btn-danger"
                                                            to="/agri-research-service/testing-service-management/sample-test-result-submission"
                                                            variant="primary">
                                                        {{ $t('globalTrans.cancel') }}
                                                    </b-link>
                                                </div>
                                            </div>
                                        </template>
                                    </iq-card>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                </template>
            </b-overlay>
        </b-col>
    </b-row>
</template>

<script>
    import { core } from '@/config/pluginInit'
    import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
    import { sampleResultShow, sampleResultSubmit, getLotNos } from '../../api/routes'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import flatpickr from 'flatpickr'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            ValidationObserver,
            ValidationProvider
        },
        created () {
            this.loads = false
            this.loading = false
            // flatpickr('.datepicker', {})
            RestApi.getData(agriResearchServiceBaseUrl, getLotNos, { general_info_id: this.$route.query.id }).then(response => {
                if (response.success) {
                    this.lotNumberList = response.data
                }
            })
        },
        data () {
            return {
                saveBtnName: this.$t('globalTrans.submit'),
                loading: false,
                loads: false,
                isLoading: false,
                isDisabled: true,
                isRequired: 'required',
                errors: [],
                tmpData: {
                    seed_name: '',
                    seed_name_id: 0,
                    lab_name: '',
                    seed_variety: '',
                    seed_class: '',
                    seed_source_name: '',
                    seed_variety_others_name: ''
                },
                formData: {
                    general_info_id: null,
                    lab_number: null,
                    lab_name: null,
                    seed_source_name: null,
                    seed_name: null,
                    seed_variety_name: null,
                    seed_class_name: null,
                    organization_address: null,
                    sample_collection_date: null,
                    lot_number: null,
                    seed_exam_start_date: null,
                    seed_exam_end_date: null,
                    comments: null,
                    pure_seed_result: null,
                    pure_seed_quality: null,
                    pure_seed_result_comments: null,
                    inert_substance_result: null,
                    inert_substance_quality: null,
                    inert_substance_comments: null,
                    others_seed_result: null,
                    others_seed_quality: null,
                    others_seed_result_comments: null,
                    seed_of_other_crops_result: null,
                    seed_of_other_crops_quality: null,
                    seed_of_other_crops_result_comments: null,
                    weed_seed_result: null,
                    weed_seed_quality: null,
                    weed_seed_result_comments: null,
                    normal_seed_result: null,
                    normal_seed_quality: null,
                    normal_seed_result_comments: null,
                    unusual_seed_result: null,
                    unusual_seed_quality: null,
                    unusual_seed_result_comments: null,
                    fresh_seed_result: null,
                    fresh_seed_quality: null,
                    fresh_seed_result_comments: null,
                    hard_seed_result: null,
                    hard_seed_quality: null,
                    hard_seed_result_comments: null,
                    dead_seed_result: null,
                    dead_seed_quality: null,
                    dead_seed_result_comments: null,
                    humidity_test_result: null,
                    humidity_test_quality: null,
                    humidity_test_result_comments: null,
                    lab_user_name: null,
                    lab_user_name_bn: null,
                    lab_office_name: null,
                    lab_office_name_bn: null,
                    lab_designation_name: null,
                    lab_designation_name_bn: null
                },
                lotNumberList: [],
                lotNumberListData: []
            }
        },
        watch: {
            currentLocale: function (newVal, oldVal) {
                this.showSeedInfoTop()
            }
        },
        mounted () {
            core.index()
            flatpickr('.datepicker', {})
        },

        computed: {
            ...mapGetters({
                authUser: 'Auth/authUser'
            }),
            currentLocale () {
                return this.$i18n.locale
            },
            labInfoList: function () {
                const labInfoList = this.$store.state.AgriResearch.commonObj.labInfoList.filter(item => item.status === 0)
                return labInfoList.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
            },
            officeList: function () {
            const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.org_id === 14)
            return officeList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        }
        },
        methods: {
            getSeedInfos () {
                  this.loads = true
                  this.loading = true
                  this.lotNumberListData = this.lotNumberList.filter(item => item.value === this.formData.seed_infos_id)
                  this.loadData()
            },
            LangCheck (bn, en) {
                  return this.$i18n.locale === 'en' ? en : bn
            },
            addreassMaker (data) {
                let value = this.holding(data) + ', ' + (data.a_village_name || '') + ', ' + (data.a_post_office_name || '') + ', ' + (this.unionName(data.a_union_id) || '') + ', ' + (this.upazilaName(data.a_upazilla_id) || '') + ', ' + (this.districtName(data.a_district_id) || '')
                // value = value.split(/\s/).filter(word => word !== 'undefined' && word !== 'null').join(' ')
                value = value.split(/,\s*/).filter(word => word !== 'null' && word !== 'undefined').join(', ')
                return value
            },
            districtName (id) {
                const data = this.$store.state.commonObj.districtList.filter(item => item.value === parseInt(id))
                if (data[0]) {
                    if (this.$i18n.locale === 'bn') {
                        return data[0].text_bn
                    } else {
                        return data[0].text_en
                    }
                }
            },
            upazilaName (id) {
                const data = this.$store.state.commonObj.upazilaList.filter(item => item.value === parseInt(id))
                if (data[0]) {
                    if (this.$i18n.locale === 'bn') {
                        return data[0].text_bn
                    } else {
                        return data[0].text_en
                    }
                }
            },
            unionName (id) {
                const data = this.$store.state.commonObj.unionList.filter(item => item.value === parseInt(id))
                if (data[0]) {
                    if (this.$i18n.locale === 'bn') {
                        return data[0].text_bn
                    } else {
                        return data[0].text_en
                    }
                }
            },
            holding (data) {
                if (this.$i18n.locale === 'bn') {
                    return data.holding_number_bn
                } else {
                    return data.holding_number_en
                }
            },
            seedSourceName (data) {
                if (this.$i18n.locale === 'bn') {
                    return data.seed_source_name_bn
                } else {
                    return data.seed_source_name_en
                }
            },
            isDisabledCheck () {
                if (parseInt(this.formData.registered) === 1) {
                    this.isDisabled = false
                    this.isRequired = ''
                } else {
                    this.isDisabled = true
                    this.isRequired = 'required'
                }
            },
            showSeedInfoTop () {
                this.tmpData.seed_name = this.$i18n.locale === 'en' ? this.lotNumberListData[0].seed_name : this.lotNumberListData[0].seed_name_bn
                this.tmpData.seed_name_id = this.lotNumberListData[0].seed_name_id
                this.tmpData.seed_variety = this.$i18n.locale === 'en' ? this.lotNumberListData[0].seed_variety_name : this.lotNumberListData[0].seed_variety_name_bn
                this.tmpData.seed_class = this.$i18n.locale === 'en' ? this.lotNumberListData[0].seed_class_name : this.lotNumberListData[0].seed_class_name_bn
                this.tmpData.seed_source_name = this.$i18n.locale === 'en' ? this.lotNumberListData[0].seed_source_name : this.lotNumberListData[0].seed_source_name_bn
                this.tmpData.seed_variety_others_name = this.lotNumberListData[0].seed_variety_others_name ?? this.lotNumberListData[0].seed_variety_others_name
                // this.tmpData.lab_name = this.lotNumberListData[0].lab_id
            },
            async loadData () {
                if (this.formData.seed_infos_id) {
                    //  this.loading = true
                    this.$store.dispatch('mutateCommonProperties', { loading: true })
                    const result = await RestApi.getData(agriResearchServiceBaseUrl, sampleResultShow + '/' + this.formData.seed_infos_id)
                    if (result.success) {
                        this.loading = false
                        this.formData = result.data
                        this.showSeedInfoTop()
                        this.formData.seed_infos_id = this.lotNumberListData[0].value
                        this.formData.seed_name = this.lotNumberListData[0].seed_name
                        this.formData.seed_variety = this.lotNumberListData[0].seed_variety_name
                        this.formData.seed_class = this.lotNumberListData[0].seed_class_name
                        this.formData.seed_source_name = this.lotNumberListData[0].seed_source_name
                        this.tmpData.lab_name = result.data.lab_id
                        this.formData.general_info_id = this.$route.query.id
                        this.formData.organization_address = this.addreassMaker(result.data)

                        if (this.authUser.office_type_id === 145 || this.authUser.office_type_id === 146 || this.authUser.office_type_id === 147) {
                            this.formData.lab_user_name = this.authUser.name
                            this.formData.lab_user_name_bn = this.authUser.name_bn
                            this.formData.lab_office_name = this.authUser.office_detail.office_name
                            this.formData.lab_office_name_bn = this.authUser.office_detail.office_name_bn
                            this.getDesignationName(this.authUser.designation_id)
                        } else {
                            this.formData.lab_user_name = ''
                            this.formData.lab_user_name_bn = ''
                            this.formData.lab_office_name = ''
                            this.formData.lab_office_name_bn = ''
                            this.formData.lab_designation_name = ''
                            this.formData.lab_designation_name_bn = ''
                        }
                        if (parseInt(result.quality.length) !== 0) {
                            this.formData.pure_seed_quality = result?.quality[0].pure_seed_quality + ' ' + this.UnitSet(result?.quality[0])
                            this.formData.inert_substance_quality = result?.quality[1].inert_substance_quality + ' ' + this.UnitSet(result?.quality[1])
                            this.formData.others_seed_quality = result?.quality[2].others_seed_quality + ' ' + this.UnitSet(result?.quality[2])
                            this.formData.seed_of_other_crops_quality = result?.quality[3].seed_of_other_crops_quality + ' ' + this.UnitSet(result?.quality[3])
                            this.formData.weed_seed_quality = result?.quality[4].weed_seed_quality + ' ' + this.UnitSet(result?.quality[4])
                            this.formData.normal_seed_quality = result?.quality[5].normal_seed_quality + ' ' + this.UnitSet(result?.quality[5])
                            this.formData.unusual_seed_quality = result?.quality[6].unusual_seed_quality + ' ' + this.UnitSet(result?.quality[6])
                            this.formData.fresh_seed_quality = result?.quality[7].fresh_seed_quality + ' ' + this.UnitSet(result?.quality[7])
                            this.formData.hard_seed_quality = result?.quality[8].hard_seed_quality + ' ' + this.UnitSet(result?.quality[8])
                            this.formData.dead_seed_quality = result?.quality[9].dead_seed_quality + ' ' + this.UnitSet(result?.quality[9])
                            this.formData.humidity_test_quality = result?.quality[10].humidity_test_quality + ' ' + this.UnitSet(result?.quality[10])
                        }
                    }
                    this.$store.dispatch('mutateCommonProperties', { loading: false })
                    this.loading = false
                }
            },
            UnitSet (data) {
                if (this.$i18n.locale === 'bn') {
                    return data.unit_bn
                } else {
                    return data.unit_en
                }
            },
            getDesignationName (designationId) {
                const designation = this.$store.state.commonObj.designationList.find(item => item.value === designationId)
                if (designation === undefined) {
                    return ''
                } else {
                    this.formData.lab_designation_name = designation.text_en
                    this.formData.lab_designation_name_bn = designation.text_bn
                }
            },
            async generalSubmit () {
                try {
                    this.loading = true
                    this.isLoading = true

                    const result = await RestApi.postData(agriResearchServiceBaseUrl, sampleResultSubmit, this.formData)

                    if (result.success) {
                        this.$toast.success({
                            title: 'Success',
                            message: this.$t('globalTrans.save_msg'),
                            color: '#D6E09B'
                        })
                        this.$router.push('/agri-research-service/testing-service-management/sample-test-result-submission')
                    } else {
                        this.$toast.error({
                            title: 'Error',
                            message: this.$t('globalTrans.form_error_msg'),
                            color: '#D6E09B'
                        })
                        this.$refs.form.setErrors(result.errors)
                    }
                } catch (error) {
                } finally {
                    this.loading = false
                    this.isLoading = false
                }
            }

        }
    }
</script>
<style scoped>
    .form-control {
        padding: 0.375rem 0.75rem
    }
</style>
